const messages = {
	en: {
		translations: {
			signup: {
				title: "Sign up",
				subtitle: "Enter your details to create your account",
				toasts: {
					success: "User created successfully! Please login!",
					fail: "Error creating user. Check the reported data.",
				},
				form: {
					name: "Name",
					email: "Email",
					password: "Password",
				},
				buttons: {
					submit: "Register",
					login: "Already have an account? Log in!",
				},
			},
			login: {
				title: "Login",
				subtitle: "Enter your details to login",
				forgtitle: "Reset",
				forgtsubtitle: "Enter your details to recover your password",
				forget: "Forgot my password",
				form: {
					email: "Enter your email",
					password: "Enter your password",
				},
				buttons: {
					submit: "Enter",
					register: "Don't have an account? Register!",
				},
			},
			plans: {
				form: {
					name: "Name",
					users: "Users",
					connections: "Channels",
					campaigns: "Campaigns",
					schedules: "Schedules",
					enabled: "Enabled",
					disabled: "Disabled",
					clear: "Cancel",
					delete: "Delete",
					save: "Save",
					yes: "Yes",
					no: "No",
					money: "$",
				},
			},
			companies: {
				title: "Register Company",
				form: {
					name: "Company Name",
					plan: "Plan",
					token: "Token",
					submit: "Register",
					success: "Company successfully created!",
				},
			},
			auth: {
				toasts: {
					success: "Login successfully!",
				},
				token: "Token",
			},
			dashboard: {
				charts: {
					perDay: {
						title: "Tickets today: ",
					},
				},
			},
			connections: {
				title: "Connections",
				toasts: {
					deleted: "WhatsApp connection deleted successfully!",
				},
				confirmationModal: {
					deleteTitle: "Delete",
					deleteMessage: "Are you sure? This action cannot be reverted.",
					disconnectTitle: "Disconnect",
					disconnectMessage:
						"Are you sure? You will need to scan the QR Code again.",
				},
				buttons: {
					add: "Add WhatsApp",
					disconnect: "Disconnect",
					tryAgain: "Try Again",
					qrcode: "QR CODE",
					newQr: "New QR CODE",
					connecting: "Connecting",
				},
				toolTips: {
					disconnected: {
						title: "Failed to start WhatsApp session",
						content:
							"Make sure your phone is connected to the internet and try again, or request a new QR Code",
					},
					qrcode: {
						title: "Waiting for QR Code scan",
						content:
							"Click 'QR CODE' and scan the QR Code with your phone to start the session",
					},
					connected: {
						title: "Connection established!",
					},
					timeout: {
						title: "The connection to the phone was lost",
						content:
							"Make sure your phone is connected to the internet and that WhatsApp is open, or click 'Disconnect' to get a new QR Code",
					},
				},
				table: {
					name: "Name",
					status: "Status",
					lastUpdate: "Last Update",
					default: "Default",
					actions: "Actions",
					session: "Session",
				},
			},
			whatsappModal: {
				title: {
					add: "Add WhatsApp",
					edit: "Edit WhatsApp",
					text: "Integrate your WhatsApp to keep your service centralized and organized in Chatsac.",
				},
				tabs: {
					general: "General",
					messages: "Messages",
					assessments: "Assessments",
					integrations: "Integrations",
					schedules: "Business Hours",
				},
				form: {
					name: "Name",
					default: "Default",
					sendIdQueue: "Queue",
					timeSendQueue: "Redirect to Queue in X minutes",
					queueRedirection: "Queue Redirection",
					outOfHoursMessage: "Out-of-hours Message",
					queueRedirectionDesc: "Select a queue for contacts without queues",
					prompt: "Prompt",
					expiresTicket: "Close open chats after X minutes",
					expiresInactiveMessage: "Inactive closure message",
					greetingMessage: "Greeting Message",
					complationMessage: "Completion Message",
				},
				buttons: {
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
				success: "WhatsApp successfully saved.",
			},
			qrCode: {
				message: "Scan the QR Code to start the session",
			},
			contacts: {
				title: "Contacts",
				toasts: {
					deleted: "Contact deleted successfully!",
					deletedAll: "All contacts deleted successfully!",
				},
				searchPlaceholder: "Search...",
				confirmationModal: {
					deleteTitle: "Delete",
					deleteAllTitle: "Delete All",
					importTitle: "Import contacts",
					deleteMessage:
						"Are you sure you want to delete this contact? All related tickets will be lost.",
					deleteAllMessage:
						"Are you sure you want to delete all contacts? All related tickets will be lost.",
					importMessage: "Do you want to import all contacts from the phone?",
				},
				buttons: {
					import: "Import Contacts",
					add: "Add Contact",
					export: "Export Contacts",
					delete: "Delete All Contacts",
				},
				table: {
					name: "Name",
					whatsapp: "WhatsApp",
					email: "Email",
					actions: "Actions",
				},
			},
			queueIntegrationModal: {
				title: {
					add: "Add project",
					edit: "Edit project",
				},
				form: {
					id: "ID",
					type: "Type",
					name: "Name",
					projectName: "Project Name",
					language: "Language",
					jsonContent: "Json Content",
					urlN8N: "URL",
					typebotSlug: "Typebot - Slug",
					typebotExpires: "Conversation expiration in minutes",
					typebotKeywordFinish: "Keyword to finish the ticket",
					typebotKeywordRestart: "Keyword to restart the flow",
					typebotRestartMessage: "Message to restart the conversation",
					typebotUnknownMessage: "Invalid option message",
					typebotDelayMessage: "Interval (ms) between messages",
				},
				buttons: {
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
					test: "Test Bot",
				},
				messages: {
					testSuccess: "Integration successfully tested!",
					addSuccess: "Integration successfully added.",
					editSuccess: "Integration successfully edited.",
				},
			},
			sideMenu: {
				name: "Initial Sidebar Menu",
				note: "If enabled, the sidebar will start closed",
				options: {
					enabled: "Open",
					disabled: "Closed",
				},
			},
			promptModal: {
				form: {
					name: "Name",
					prompt: "Prompt",
					voice: "Voice",
					max_tokens: "Max Tokens in Response",
					temperature: "Temperature",
					apikey: "API Key",
					max_messages: "Max Messages in History",
					voiceKey: "Voice API Key",
					voiceRegion: "Voice Region",
				},
				success: "Prompt successfully saved!",
				title: {
					add: "Add Prompt",
					edit: "Edit Prompt",
				},
				buttons: {
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
			},
			prompts: {
				title: "Prompts",
				table: {
					name: "Name",
					queue: "Queue",
					max_tokens: "Max Tokens in Response",
					actions: "Actions",
				},
				confirmationModal: {
					deleteTitle: "Delete",
					deleteMessage: "Are you sure? This action cannot be undone!",
				},
				buttons: {
					add: "Add Prompt",
				},
			},
			contactModal: {
				title: {
					add: "Add contact",
					edit: "Edit contact",
				},
				form: {
					mainInfo: "Contact details",
					extraInfo: "Additional information",
					name: "Name",
					number: "Whatsapp number",
					email: "Email",
					extraName: "Field name",
					extraValue: "Value",
					whatsapp: "Source Connection: ",
				},
				buttons: {
					addExtraInfo: "Add information",
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
				success: "Contact saved successfully.",
			},
			queueModal: {
				title: {
					add: "Add Queue",
					edit: "Edit Queue",
				},
				form: {
					name: "Name",
					color: "Color",
					greetingMessage: "Greeting Message",
					complationMessage: "Completion Message",
					outOfHoursMessage: "Out-of-hours Message",
					ratingMessage: "Rating Message",
					token: "Token",
					orderQueue: "Queue Order (Bot)",
					integrationId: "Integration",
					formOptions: {
						options: "Options",
						addOption: "ADD",
						placeholderTitleOptions: "Option title",
						placeholderOptions: "Enter option text",
						titleIsNotdefined: "Title not defined",
					},
				},
				buttons: {
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
			},
			userModal: {
				title: {
				  add: "Add user",
				  edit: "Edit user",
				},
				form: {
				  name: "Name",
				  email: "Email",
				  password: "Password",
				  profile: "Profile",
				  whatsapp: "Default Connection",
				  allTicket: "Ticket Without Queue [Invisible]",
				  allTicketEnabled: "Enabled",
				  allTicketDesabled: "Disabled",
				},
				buttons: {
				  okAdd: "Add",
				  okEdit: "Save",
				  cancel: "Cancel",
				},
				success: "User successfully saved.",
			  },
			  scheduleModal: {
				subtitle: "Optimize your customer service and save time.",
				title: {
				  add: "New Schedule",
				  edit: "Edit Schedule",
				},
				form: {
				  body: "Message",
				  contact: "Contact",
				  sentAt: "Sent Date",
				  sendAtDate: "Schedule Date",
				  sendAtTime: "Schedule Time",
				},
				buttons: {
				  okAdd: "Add",
				  okEdit: "Save",
				  cancel: "Cancel",
				},
				success: "Schedule successfully saved.",
			  },
			  tagModal: {
				title: {
				  add: "New Tag",
				  edit: "Edit Tag",
				},
				form: {
				  name: "Name",
				  color: "Color",
				},
				buttons: {
				  okAdd: "Add",
				  okEdit: "Save",
				  cancel: "Cancel",
				},
				success: "Tag successfully saved.",
			  },
			  chat: {
				noTicketMessage: "Select a ticket to start chatting.",
			  },
			  uploads: {
				titles: {
				  titleUploadMsgDragDrop: "DRAG AND DROP FILES INTO THE FIELD BELOW",
				  titleFileList: "File(s) List",
				},
			  },
			  ticketsManager: {
				buttons: {
				  newTicket: "New",
				},
			  },
			  ticketsQueueSelect: {
				placeholder: "Queues",
			  },
			  tickets: {
				toasts: {
				  deleted: "The ticket you were on has been deleted.",
				},
				notification: {
				  message: "Message from",
				},
				tabs: {
				  open: { title: "Inbox" },
				  closed: { title: "Resolved" },
				  search: { title: "Search" },
				},
				search: {
				  placeholder: "Search tickets and messages.",
				},
				buttons: {
				  showAll: "All",
				},
			  },
			  transferTicketModal: {
				title: "Transfer Ticket",
				fieldLabel: "Type to search for users",
				fieldQueueLabel: "Transfer to queue",
				fieldQueuePlaceholder: "Select a queue",
				noOptions: "No user found with this name",
				buttons: {
				  ok: "Transfer",
				  cancel: "Cancel",
				},
			  },
			  ticketsList: {
				pendingHeader: "Waiting",
				finished: "Finished",
				assignedHeader: "Working on",
				noTicketsTitle: "Nothing here!",
				noTicketsMessage:
				  "No tickets found with this status or search term",
				buttons: {
				  accept: "Accept",
				  closed: "Finish",
				  reopen: "Reopen",
				},
			  },
			  customersWaiting: {
				title: "Customers waiting",
				buttons: {
				  to_meeting: "Attend",
				},
			  },
			  newTicketModal: {
				title: "Create Ticket",
				fieldLabel: "Type to search for a contact",
				add: "Add",
				queue: "Select a queue",
				connection: "Select a connection",  
				buttons: {
				  ok: "Save",
				  cancel: "Cancel",
				},
			  },
			  mainDrawer: {
				listItems: {
				  dashboard: "Dashboard",
				  connections: "Connections",
				  tickets: "Tickets",
				  quickMessages: "Quick Responses",
				  contacts: "Contacts",
				  queues: "Queues & Chatbot",
				  tags: "Tags",
				  administration: "Administration",
				  users: "Users",
				  settings: "Settings",
				  helps: "Help",
				  messagesAPI: "API",
				  schedules: "Schedules",
				  campaigns: "Campaigns",
				  annoucements: "Announcements",
				  chats: "Internal Chat",
				  financeiro: "Finance",
				  files: "File List",
				  prompts: "Open.AI",
				  queueIntegration: "Integrations",
				},
				menu: {
				  listing: "Listing",
				  contacts_list: "Contacts Lists",
				  settings: "Settings",
				},
				appBar: {
				  notRegister: "No notifications",
				  user: {
					profile: "Profile",
					logout: "Logout",
				  },
				},
			  },
			  queueIntegration: {
				title: "Integrations",
				table: {
				  id: "ID",
				  type: "Type",
				  name: "Name",
				  projectName: "Project Name",
				  language: "Language",
				  lastUpdate: "Last update",
				  actions: "Actions",
				},
				buttons: {
				  add: "Add Project",
				},
				searchPlaceholder: "Search...",
				confirmationModal: {
				  deleteTitle: "Delete",
				  deleteMessage:
					"Are you sure? This action cannot be undone and it will be removed from linked queues and channels.",
				},
			  },
			  files: {
				title: "File List",
				table: {
				  name: "Name",
				  contacts: "Contacts",
				  actions: "Actions",
				},
				toasts: {
				  deleted: "List successfully deleted!",
				  deletedAll: "All lists deleted successfully!",
				},
				buttons: {
				  add: "Add",
				  deleteAll: "Delete All",
				},
				confirmationModal: {
				  deleteTitle: "Delete",
				  deleteAllTitle: "Delete All",
				  deleteMessage: "Are you sure you want to delete this list?",
				  deleteAllMessage: "Are you sure you want to delete all lists?",
				},
			  },
			  messagesAPI: {
				title: "API",
				textMessage: {
				  number: "Number",
				  body: "Message",
				  token: "Registered Token",
				},
				mediaMessage: {
				  number: "Number",
				  body: "File Name",
				  media: "File",
				  token: "Registered Token",
				  noFileChosen: "No file chosen",
				  chooseFile: "Choose file",
				},
				buttons: {
				  send: "Send",
				},
				documentationTitle: "Message Sending Documentation",
				sendMethods: "Sending Methods",
				methods: {
				  textMessages: "Text Messages",
				  mediaMessages: "Media Messages",
				},
				instructionsTitle: "Instructions",
				importantNotes: "Important Notes",
				notes: {
				  beforeSending:
					"Before sending messages, you must register the token linked to the connection that will send the messages. To register, go to the 'Connections' menu, click the edit button of the connection, and enter the token in the appropriate field.",
				  numberFormat:
					"The number to send must not have a mask or special characters and must consist of:",
				  countryCode: "Country code",
				  areaCode: "Area code",
				  number: "Number",
				},
				textMessagesTitle: "1. Text Messages",
				textInstructions:
				  "Below is the list of information needed to send text messages:",
				endpoint: "Endpoint",
				method: "Method",
				headers: "Headers",
				body: "Body",
				testSending: "Test Sending",
				mediaMessagesTitle: "2. Media Messages",
				mediaInstructions:
				  "Below is the list of information needed to send media:",
				formData: "FormData",
				success: {
				  sent: "Message sent successfully",
				},
				error: {
				  noPermission:
					"This company does not have permission to access this page! We are redirecting you.",
				},
			  },
			notifications: {
				noTickets: "No notifications.",
			  },
			  quickMessages: {
				title: "Quick Responses",
				searchPlaceholder: "Search...",
				noAttachment: "No attachment",
				confirmationModal: {
				  deleteTitle: "Delete",
				  deleteMessage: "This action is irreversible! Do you want to proceed?",
				},
				buttons: {
				  add: "Add",
				  attach: "Attach File",
				  cancel: "Cancel",
				  edit: "Edit",
				},
				toasts: {
				  success: "Shortcut successfully added!",
				  deleted: "Shortcut successfully deleted!",
				},
				dialog: {
				  title: "Quick Response",
				  shortcode: "Shortcut",
				  message: "Response",
				  save: "Save",
				  cancel: "Cancel",
				  geral: "Allow editing",
				  add: "Add quick response",
				  edit: "Edit",
				  visao: "Allow view",
				},
				table: {
				  shortcode: "Shortcut",
				  message: "Message",
				  actions: "Actions",
				  mediaName: "File Name",
				  status: "Status",
				},
			  },
			  messageVariablesPicker: {
				label: "Available Variables",
				sublabel: "Use 'First Name', 'Name', 'Greeting', 'Protocol' and 'Hour' for automatic personalization.",
				vars: {
				  contactFirstName: "First Name",
				  contactName: "Name",
				  greeting: "Greeting",
				  protocolNumber: "Protocol",
				  date: "Date",
				  hour: "Hour",
				},
			  },
			  contactLists: {
				title: "Contact Lists",
				table: {
				  name: "Name",
				  contacts: "Contacts",
				  actions: "Actions",
				},
				buttons: {
				  add: "New List",
				},
				dialog: {
				  name: "Name",
				  company: "Company",
				  okEdit: "Edit",
				  okAdd: "Add",
				  add: "Add",
				  edit: "Edit",
				  cancel: "Cancel",
				},
				confirmationModal: {
				  deleteTitle: "Delete",
				  deleteMessage: "This action cannot be undone.",
				},
				toasts: {
				  deleted: "Record deleted",
				},
			  },
			  contactListItems: {
				title: "Contacts",
				searchPlaceholder: "Search",
				buttons: {
				  add: "New",
				  lists: "Lists",
				  import: "Import",
				},
				dialog: {
				  name: "Name",
				  number: "Number",
				  whatsapp: "Whatsapp",
				  email: "E-mail",
				  okEdit: "Edit",
				  okAdd: "Add",
				  add: "Add",
				  edit: "Edit",
				  cancel: "Cancel",
				},
				table: {
				  name: "Name",
				  number: "Number",
				  whatsapp: "Whatsapp",
				  email: "E-mail",
				  actions: "Actions",
				},
				confirmationModal: {
				  deleteTitle: "Delete",
				  deleteMessage: "This action cannot be undone.",
				  importMessage: "Do you want to import the contacts from this spreadsheet?",
				  importTitlte: "Import",
				},
				toasts: {
				  deleted: "Record deleted",
				},
			  },
			  campaigns: {
				title: "Campaigns",
				searchPlaceholder: "Search",
				buttons: {
				  add: "New Campaign",
				  contactLists: "Contact Lists",
				},
				table: {
				  name: "Name",
				  whatsapp: "Connection",
				  contactList: "Contact List",
				  status: "Status",
				  scheduledAt: "Scheduled",
				  completedAt: "Completed",
				  confirmation: "Confirmation",
				  actions: "Actions",
				},
				dialog: {
				  new: "New Campaign",
				  update: "Edit Campaign",
				  readonly: "View Only",
				  form: {
					name: "Name",
					message1: "Message 1",
					message2: "Message 2",
					message3: "Message 3",
					message4: "Message 4",
					message5: "Message 5",
					confirmationMessage1: "Confirmation Message 1",
					confirmationMessage2: "Confirmation Message 2",
					confirmationMessage3: "Confirmation Message 3",
					confirmationMessage4: "Confirmation Message 4",
					confirmationMessage5: "Confirmation Message 5",
					messagePlaceholder: "Message content",
					whatsapp: "Connection",
					status: "Status",
					scheduledAt: "Scheduled",
					confirmation: "Confirmation",
					contactList: "Contact List",
					tagList: "Tag List",
					fileList: "File List",
					text_footer: "Use variables like {name}, {number}, {email}, or define custom variables.",
				  },
				  buttons: {
					add: "Add",
					edit: "Update",
					okadd: "Ok",
					cancel: "Cancel Campaign",
					restart: "Restart Campaign",
					close: "Close",
					attach: "Attach File",
				  },
				},
				confirmationModal: {
				  deleteTitle: "Delete",
				  deleteMessage: "This action cannot be undone.",
				},
				toasts: {
				  success: "Operation successfully completed",
				  cancel: "Campaign canceled",
				  restart: "Campaign restarted",
				  deleted: "Record deleted",
				},
			  },
			  announcements: {
				active: "Active",
				inactive: "Inactive",
				title: "Announcements",
				searchPlaceholder: "Search",
				buttons: {
				  add: "New Announcement",
				  contactLists: "Announcement Lists",
				},
				table: {
				  priority: "Priority",
				  title: "Title",
				  text: "Text",
				  mediaName: "File",
				  status: "Status",
				  actions: "Actions",
				},
				dialog: {
				  edit: "Edit Announcement",
				  add: "New Announcement",
				  update: "Update Announcement",
				  readonly: "View Only",
				  form: {
					priority: "Priority",
					title: "Title",
					text: "Text",
					mediaPath: "File",
					status: "Status",
					input_status: {
					  Active: "Active",
					  Inactive: "Inactive",
					},
					input_priority: {
					  High: "High",
					  Medium: "Medium",
					  Low: "Low",
					},
				  },
				  buttons: {
					add: "Add",
					edit: "Update",
					okadd: "Ok",
					cancel: "Cancel",
					close: "Close",
					attach: "Attach File",
				  },
				},
				confirmationModal: {
				  deleteTitle: "Delete",
				  deleteMessage: "This action cannot be undone.",
				},
				toasts: {
				  success: "Operation successfully completed",
				  deleted: "Record deleted",
				},
			  },
			  campaignsConfig: {
				title: "Campaigns Settings",
				form: {
				  intervals: "Intervals",
				  randomInterval: "Random Shooting Interval",
				  longerIntervalAfter: "Longer Interval After",
				  greaterInterval: "Greater Shooting Interval",
				  addVariable: "Add Variable",
				  saveSettings: "Save Settings",
				  shortcut: "Shortcut",
				  content: "Content",
				  noInterval: "No Interval",
				  close: "Close",
				  add: "Add",
				},
				confirmationModal: {
				  deleteTitle: "Delete Variable",
				  deleteMessage: "This action cannot be undone. Are you sure you want to delete this variable?",
				},
				menuItems: {
				  second: "{{value}} seconds",
				  undefined: "Undefined",
				},
				table: {
				  shortcut: "Shortcut",
				  content: "Content",
				  actions: "Actions",
				},
			  },
			  queues: {
				title: "Queues & Chatbot",
				table: {
				  id: "ID",
				  name: "Name",
				  color: "Color",
				  greeting: "Greeting Message",
				  actions: "Actions",
				  orderQueue: "Queue Order (Bot)",
				},
				buttons: {
				  add: "Add Queue",
				},
				confirmationModal: {
				  deleteTitle: "Delete",
				  deleteMessage:
					"Are you sure? This action cannot be undone! The tickets in this queue will still exist but will no longer have any queue assigned.",
				},
			  },
			  queueSelect: {
				inputLabel: "Queues",
			  },
			  users: {
				title: "Users",
				table: {
				  id: "ID",
				  name: "Name",
				  email: "Email",
				  profile: "Profile",
				  actions: "Actions",
				},
				buttons: {
				  add: "Add user",
				},
				toasts: {
				  deleted: "User deleted successfully.",
				},
				confirmationModal: {
				  deleteTitle: "Delete",
				  deleteMessage:
					"All user data will be lost. The user's open tickets will be moved to the queue.",
				},
			  },
			  helps: {
				title: "Help Center",
			  },
			  schedules: {
				title: "Schedules",
				confirmationModal: {
				  deleteTitle: "Are you sure you want to delete this Schedule?",
				  deleteMessage: "This action cannot be undone.",
				},
				table: {
				  contact: "Contact",
				  body: "Message",
				  sendAt: "Schedule Date",
				  sentAt: "Sent Date",
				  status: "Status",
				  actions: "Actions",
				},
				buttons: {
				  add: "New Schedule",
				},
				toasts: {
				  deleted: "Schedule successfully deleted.",
				},
			  },
			tags: {
				title: "Tags",
				confirmationModal: {
				  deleteTitle: "Are you sure you want to delete this tag?",
				  deleteMessage: "This action cannot be undone.",
				  deleteAllMessage: "Are you sure you want to delete all tags?",
				  deleteAllTitle: "Delete All",
				},
				table: {
				  name: "Name",
				  color: "Color",
				  tickets: "Tagged Records",
				  actions: "Actions",
				},
				buttons: {
				  add: "New Tag",
				  deleteAll: "Delete All",
				},
				toasts: {
				  deletedAll: "All tags deleted successfully!",
				  deleted: "Tag deleted successfully.",
				},
			  },
			  settings: {
				success: "Settings saved successfully.",
				title: "Settings",
				tab: {
				  title: {
					options: "Options",
					companies: "Companies",
					plans: "Plans",
				  },
				  options: {
					labels: {
					  userRating: "User Ratings",
					  scheduleType: "Working Hours Management",
					  callType: "Accept Call",
					  chatbotType: "Chatbot Type",
					  groupType: "Ignore Group Messages",
					  sendGreetingAccepted: "Greeting when ticket is accepted",
					  sendMsgTransfTicket: "Message on Queue/Agent transfer",
					  sendGreetingMessageOneQueues: "Greeting when there is only 1 queue",
					  updating: "Updating...",
					  disabled: "Disabled",
					  enabled: "Enabled",
					  queue: "Queue",
					  company: "Company",
					  text: "Text",
					  noAccept: "Do Not Accept",
					  accept: "Accept",
					},
					toasts: {
					  success: "Operation updated successfully.",
					},
				  },
				},
				settings: {
				  userCreation: {
					name: "User creation",
					options: {
					  enabled: "Enabled",
					  disabled: "Disabled",
					},
				  },
				},
			  },
			  messagesList: {
				header: {
				  assignedTo: "Assigned to:",
				  buttons: {
					return: "Return",
					resolve: "Finish",
					reopen: "Reopen",
					accept: "Accept",
				  },
				},
			  },
			  messagesInput: {
				placeholderOpen: "Type a message",
				placeholderClosed: "Reopen or accept this ticket to send a message.",
				signMessage: "Sign",
				pendding: {
				  delete: "Delete",
				  transfer: "Transfer",
				  attend: "Attend",
				  confirmationTitle: "Are you sure you want to delete ticket #{{ticketId}}?",
				  confirmationMessage:
					"Attention! All messages related to the ticket will be lost.",
				},
			  },
			  contactDrawer: {
				header: "Contact details",
				buttons: {
				  edit: "Edit contact",
				},
				extraInfo: "Other information",
			  },
			  fileModal: {
				title: {
				  add: "Add file list",
				  edit: "Edit file list",
				},
				buttons: {
				  okAdd: "Save",
				  okEdit: "Edit",
				  cancel: "Cancel",
				  fileOptions: "Add file",
				},
				form: {
				  name: "File list name",
				  message: "File list details",
				  fileOptions: "File list",
				  extraName: "Message to send with file",
				  extraValue: "Value",
				},
				success: "File list successfully saved!",
			  },
			  ticketOptionsMenu: {
				schedule: "Schedule",
				delete: "Delete",
				transfer: "Transfer ticket",
				registerAppointment: "Contact's Observations",
				appointmentsModal: {
				  title: "Contact's Observations",
				  textarea: "Observation",
				  placeholder: "Insert here the information you want to register",
				},
				confirmationModal: {
				  title: "Delete ticket",
				  titleFrom: "from contact ",
				  message: "Attention! All messages related to the ticket will be lost.",
				},
				buttons: {
				  delete: "Delete",
				  cancel: "Cancel",
				},
			  },
			  confirmationModal: {
				buttons: {
				  confirm: "Ok",
				  cancel: "Cancel",
				},
			  },
			  messageOptionsMenu: {
				delete: "Delete",
				reply: "Reply",
				confirmationModal: {
				  title: "Delete message?",
				  message: "This action cannot be undone.",
				},
			  },
			  backendErrors: {
				ERR_NO_OTHER_WHATSAPP: "There must be at least one default WhatsApp.",
				ERR_NO_DEF_WAPP_FOUND:
				  "No default WhatsApp found. Check the connections page.",
				ERR_WAPP_NOT_INITIALIZED:
				  "This WhatsApp session has not been initialized. Check the connections page.",
				ERR_WAPP_CHECK_CONTACT:
				  "Could not check WhatsApp contact. Check the connections page.",
				ERR_WAPP_INVALID_CONTACT: "This is not a valid WhatsApp number.",
				ERR_WAPP_DOWNLOAD_MEDIA:
				  "Unable to download media from WhatsApp. Check the connections page.",
				ERR_INVALID_CREDENTIALS:
				  "Invalid credentials. Please check your username and password and try again.",
				ERR_SENDING_WAPP_MSG:
				  "Error sending WhatsApp message. Check the connections page.",
				ERR_DELETE_WAPP_MSG: "Unable to delete WhatsApp message.",
				ERR_OTHER_OPEN_TICKET: "There is already an open ticket for this contact.",
				ERR_SESSION_EXPIRED: "Session expired. Please login.",
				ERR_USER_CREATION_DISABLED: "User creation has been disabled by the administrator.",
				ERR_NO_PERMISSION: "You do not have permission to access this resource.",
				ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
				ERR_NO_SETTING_FOUND: "No setting found with this ID.",
				ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
				ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
				ERR_NO_USER_FOUND: "No user found with this ID.",
				ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
				ERR_CREATING_MESSAGE: "Error creating message in the database.",
				ERR_CREATING_TICKET: "Error creating ticket in the database.",
				ERR_FETCH_WAPP_MSG:
				  "Error fetching message from WhatsApp, maybe it's too old.",
				ERR_QUEUE_COLOR_ALREADY_EXISTS: "This color is already in use, choose another.",
				ERR_WAPP_GREETING_REQUIRED:
				  "The greeting message is mandatory when there is more than one queue.",
			  },
			  dashboard: {
				title: "Your Metrics",
				cards: {
				  in_conversation: "In Conversation",
				  waiting: "Waiting",
				  finished: "Finished",
				  new_contacts: "New Contacts",
				  average_conversation_time: "Avg. Conversation Time",
				  average_waiting_time: "Avg. Waiting Time",
				},
				filter: {
				  title: "Filter Type",
				  type: {
					date: "Date Filter",
					period: "Period Filter",
				  },
				  date: {
					from: "Start Date",
					to: "End Date",
				  },
				  period: {
					none_selected: "None Selected",
					last_3_days: "Last 3 days",
					last_7_days: "Last 7 days",
					last_15_days: "Last 15 days",
					last_30_days: "Last 30 days",
					last_60_days: "Last 60 days",
					last_90_days: "Last 90 days",
				  },
				  placeholder: "Select desired period",
				},
				buttons: {
				  filter: "FILTER",
				},
				table: {
				  name: "Name",
				  evaluations: "Evaluations",
				  service_time: "Avg. Service Time",
				  last_update: "Last Update",
				  status: "Current Status",
				},
				bar_chart: {
				  title: "Total Conversations by User",
				  y_axis_title: "Conversation Chart",
				  data: {
					start: "Start",
					end: "End",
				  },
				  buttons: {
					filter: "Filter",
				  },
				},
				bar_chart_total: {
				  title: "Total",
				  y_axis_title: "Conversation Chart",
				  data: {
					start: "Start",
					end: "End",
				  },
				  buttons: {
					filter: "Filter",
				  },
				},
			  },
			  kanban: {
				buttons: {
				  create_step: "Create Step",
				  open_ticket: "View Ticket",
				},
				step_open: "Open",
			  },
			  calendar: {
				date: "Date",
				time: "Time",
				event: "Event",
				allDay: "All Day",
				week: "Week",
				work_week: "Work Week",
				day: "Day",
				month: "Month",
				previous: "Previous",
				next: "Next",
				yesterday: "Yesterday",
				tomorrow: "Tomorrow",
				today: "Today",
				agenda: "Agenda",
				noEventsInRange: "No events in range.",
				showMore: "+{{count}} more",
				weekdays: {
				  sunday: "Sunday",
				  monday: "Monday",
				  tuesday: "Tuesday",
				  wednesday: "Wednesday",
				  thursday: "Thursday",
				  friday: "Friday",
				  saturday: "Saturday",
				},
			  },
			  companiesManager: {
				table: {
				  name: "Name",
				  email: "Email",
				  phone: "Phone",
				  plan: "Plan",
				  campaigns: "Campaigns",
				  status: "Status",
				  createdAt: "Created At",
				  dueDate: "Due Date",
				  recurrence: "Recurrence",
				  edit: "Edit",
				  delete: "Delete",
				},
				form: {
				  titleAdd: "Add Company",
				  titleEdit: "Edit Company",
				  name: "Name",
				  email: "Email",
				  phone: "Phone",
				  plan: "Plan",
				  status: "Status",
				  campaigns: "Campaigns",
				  dueDate: "Due Date",
				  cancel: "Cancel",
				  save: "Save Changes",
				  user: "User",
				},
				status: {
				  active: "Active",
				  inactive: "Inactive",
				},
				campaignsStatus: {
				  enabled: "Enabled",
				  disabled: "Disabled",
				},
				confirmDeletion: {
				  title: "Record Deletion",
				  message: "Are you sure you want to delete this record?",
				},
				toast: {
				  errorCompanies: "Failed to load record list",
				  errorLoadCompanies: "Error fetching companies",
				  successUpdate: "Operation successful!",
				  errorUpdate: "Error saving data.",
				  successDelete: "Company deleted successfully!",
				  errorDelete: "Error deleting company.",
				  errorUsers: "Error loading company users.",
				  successLoad: "Operation updated successfully!",
				  errorNotCompanie: "Company has not been created yet. Please save the company first.",
				},
				searchPlaceholder: "Search",
				addButton: "+ Add Company",
				updating: "Updating...",
			  },
			  plansManager: {
				table: {
				  name: "Name",
				  users: "Users",
				  connections: "Connections",
				  queues: "Queues",
				  value: "Value",
				  useCampaigns: "Campaigns",
				  actions: "Actions",
				},
				form: {
				  addTitle: "Add Plan",
				  editTitle: "Edit Plan",
				  name: "Name",
				  users: "Users",
				  connections: "Connections",
				  queues: "Queues",
				  value: "Value",
				  useCampaigns: "Campaigns",
				  useSchedules: "Schedules",
				  useInternalChat: "Internal Chat",
				  useExternalApi: "External API",
				  useKanban: "Kanban",
				  useOpenAi: "Open AI",
				  useIntegrations: "Integrations",
				  enabled: "Enabled",
				  disabled: "Disabled",
				  cancel: "Cancel",
				  save: "Save",
				},
				searchPlaceholder: "Search",
				addButton: "+ Add Plan",
				confirmDeletion: {
				  title: "Delete Plan",
				  message: "Are you sure you want to delete this plan?",
				},
				toast: {
				  errorLoadPlans: "Failed to load the list of records",
				  errorSearchPlans: "Error fetching plans",
				  successDelete: "Plan deleted successfully!",
				  errorDelete: "Error deleting plan.",
				  successAdd: "Plan added successfully!",
				  successUpdate: "Plan updated successfully!",
				  errorSave: "Error saving the plan. Check the fields and try again.",
				},
				updating: "Updating...",
			  },
		},
	},
};

export { messages };
