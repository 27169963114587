import React, { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { ptBR } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";
import { SocketContext, SocketManager } from './context/Socket/SocketContext';

import { i18n } from "./translate/i18n";

import Routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
    const [locale, setLocale] = useState(ptBR);

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const preferredTheme = window.localStorage.getItem("preferredTheme");
    const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light");

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        []
    );

    // Carregar o idioma salvo no i18next
    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng") || "pt"; // Padrão como pt se não houver idioma salvo
        i18n.changeLanguage(i18nlocale); // Garante que o i18n use o idioma salvo
        if (i18nlocale.startsWith("pt")) {
          setLocale(ptBR);
        }
      }, []);

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode);
    }, [mode]);

    const theme = createTheme(
        {
            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: '2px',
                    height: '2px',
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: "#6EB131",
                },
            },
            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "2px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? "#6EB131" : "#6EB131",
                },
            },
            palette: {
                type: mode,
                primary: { main: mode === "light" ? "#6EB131" : "#fff" },
                subtexts: { main: mode === "light" ? "#1F2937" : "#FFFFFF" },
                textPrimary: mode === "light" ? "#87C945" : "#FFFFFF",
                borderPrimary: mode === "light" ? "#87C945" : "#FFFFFF",
                dark: { main: mode === "light" ? "#111433" : "#F3F3F3" },
                light: { main: mode === "light" ? "#0A0A0A" : "#fff" },
                tabHeaderBackground: mode === "light" ? "#EEE" : "#666",
                optionsBackground: mode === "light" ? "#fafafa" : "#111433",
                options: mode === "light" ? "#fafafa" : "#666",
                fontecor: mode === "light" ? "#128c7e" : "#fff",
                fancyBackground: mode === "light" ? "#fafafa" : "#111433",
                sidebar: mode === "light" ? "#fff" : "linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(88, 104, 179, 0.10 ) 100%)",
                sidebarMobile: mode === "light" ? "#fff" : "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(17,20,51,1) 100%)",
                bordabox: mode === "light" ? "#eee" : "#111433",
                newmessagebox: mode === "light" ? "#eee" : "#111433",
                inputdigita: mode === "light" ? "#fff" : "#666",
                contactdrawer: mode === "light" ? "#fff" : "#666",
                announcements: mode === "light" ? "#ededed" : "#111433",
                login: mode === "light" ? "#fff" : "#1C1C1C",
                announcementspopover: mode === "light" ? "#fff" : "#666",
                chatlist: mode === "light" ? "#eee" : "#666",
                boxlist: mode === "light" ? "#ededed" : "#666",
                boxchatlist: mode === "light" ? "#ededed" : "#111433",
                total: mode === "light" ? "#fff" : "#222",
                messageIcons: mode === "light" ? "grey" : "#F3F3F3",
                inputBackground: mode === "light" ? "#FFFFFF" : "#111433",
                barraSuperior: mode === "light" ? "transparent" : "transparent",
                boxticket: mode === "light" ? "#EEE" : "#666",
                cardDashboard: mode === "light" ? "#6EB131" : "#1D203D",
                cardnormal: mode === "light" ? "#fff" : "#1D203D",
                campaigntab: mode === "light" ? "#ededed" : "#666",
                mediainput: mode === "light" ? "#ededed" : "#1c1c1c",
                contrastText: mode === "light" ? "#fff" : "#1C1C1C",
                textos: mode === "light" ? "#1C1C1C" : "#fff",
                textosKanbanHeader: mode === "light" ? "#000" : "#fff",
                sidebarbuttons: mode === "light" ? "#6EB131" : "#6EB131",
                inputBackgroundColor: mode === "light" ? "#F2F2F2" : "#262945",
                messageBackground: mode === "light" ? "#F9FBFC" : "#111433"
            },
            typography: {
                fontFamily: "Poppins, Arial, sans-serif", // Fonte global
                // h5: {
                //   fontSize: "2rem",
                //   fontWeight: 700,
                //   color: mode === "light" ? "#1C1C1C" : "#FFFFFF",
                // },
                // h2: {
                //   fontSize: "1.75rem",
                //   fontWeight: 600,
                //   color: mode === "light" ? "#1C1C1C" : "#FFFFFF",
                // },
                // body1: {
                //   fontSize: "1rem",
                //   fontWeight: 400,
                //   color: mode === "light" ? "#1C1C1C" : "#FFFFFF",
                // },
                // Outras configurações de tipografia
            },
            overrides: {
                MuiFormControl: {
                    marginDense: {
                      marginTop: "24px",
                      marginBottom: "4px",
                    },
                    root: {
                      // Este é o seletor correto para aplicar estilos ao `FormControl` com a variante `marginDense`
                      '&.MuiFormControl-marginDense': {
                        marginTop: "24px",
                        marginBottom: "4px",
                      },
                    },
                  },
                MuiOutlinedInput: {
                    root: {
                        '& $notchedOutline': {
                            borderColor: 'transparent',
                        },
                        '&:hover $notchedOutline': {
                            borderColor: 'transparent',
                        },
                        '&$focused $notchedOutline': {
                            borderColor: 'transparent',
                        },
                    },
                    input: {
                        backgroundColor: mode === "light" ? "#F2F2F2" : "#262945",
                        border: mode === "light" ? '1px solid #DBDBDB' : '1px solid #53556C',
                        borderRadius: "8px",
                    },
                },
                MuiInputLabel: {
                    root: {
                        color: mode === "light" ? "#1F2937" : "#FFFFFF",
                        '&:hover': {
                            color: mode === "light" ? "#1F2937" : "#FFFFFF",
                        },
                        '&$focused': {
                            color: mode === "light" ? "#1F2937" : "#FFFFFF",
                        },
                    },
                },
                MuiLink: {
                    root: {
                        color: mode === "light" ? "#000000" : "#FFFFFF",
                        '&:hover': {
                            color: mode === "light" ? "#111433" : "#F3F3F3",
                        },
                    },
                },
                // MuiButton: {
                //     root: {
                //         color: mode === "light" ? "#6EB131" : "#6EB131",
                //         backgroundColor: mode === "light" ? "#6EB131" : "#6EB131",
                //         '&:hover': {
                //             color: mode === "light" ? "#fff" : "#F3F3F3",
                //         },
                //     },
                // },
                // MuiFormControl: {
                //     root: {
                //         size: 'small',
                //     },
                // },
                MuiTextField: {
                    root: {
                        size: 'small',
                        //   '& label.Mui-focused': {
                        //     color: '#6EB131',  // Cor do label quando o campo está focado
                        //   },
                        //   '& .MuiInput-underline:after': {
                        //     borderBottomColor: '#6EB131',  // Cor da borda após o foco
                        //   },
                        //   '& .MuiOutlinedInput-root': {
                        //     '& fieldset': {
                        //       borderColor: '#DBDBDB',  // Cor da borda
                        //     },
                        //     '&:hover fieldset': {
                        //       borderColor: '#6EB131',  // Cor da borda ao passar o mouse
                        //     },
                        //     '&.Mui-focused fieldset': {
                        //       borderColor: '#6EB131',  // Cor da borda quando focado
                        //     },
                        //   },
                        '& .MuiInputLabel-root': {
                            transform: 'translate(0, -22px) scale(1)',  // Mover o label para cima
                            fontWeight: '600',  // Tornar o label mais peso
                            fontSize: '14px',  // Aumentar o tamanho do label

                        },
                        '& .MuiOutlinedInput-multiline': {
                            padding: '0px', // Remova padding extra
                        },
                    },
                },
                MuiSelect: {
                    height: '40px !important', // Força a altura
                    '& .MuiSelect-select': {
                        padding: '10px 14px', // Ajusta o padding para centralização
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
                MuiTypography: {
                    gutterBottom: {
                        marginBottom: '1rem', // Altera o espaçamento padrão do gutterBottom
                    },
                    root: {
                        color: mode === "light" ? "#1C1C1C" : "#FFFFFF", // Cor padrão para todos os Typography
                    },
                },
                MuiButton: {
                    root: {
                        borderRadius: '16px'
                    },
                    containedPrimary: {
                        backgroundColor: mode === "light" ? "#6EB131" : "#6EB131", // Cor de fundo do botão primário
                        color: mode === "light" ? "#fff" : "#111433", // Cor do texto do botão primário
                        borderRadius: '16px'
                        //   '&:hover': {
                        //     backgroundColor: mode === "light" ? "#5EA120" : "#5EA120", // Cor de fundo do botão ao passar o mouse
                        //   },
                    },
                    // outlinedPrimary: {
                    //   borderColor: mode === "light" ? "#6EB131" : "#6EB131", // Cor da borda do botão primário contornado
                    //   color: mode === "light" ? "#6EB131" : "#6EB131", // Cor do texto no botão primário contornado
                    //   '&:hover': {
                    //     backgroundColor: mode === "light" ? "rgba(110, 177, 49, 0.08)" : "rgba(110, 177, 49, 0.08)", // Cor de fundo ao passar o mouse no botão contornado
                    //   },
                    // },

                    // Outras personalizações de componentes...
                },

                MuiPaper: {
                    styleOverrides: {
                        root: {
                            backgroundColor: 'red'

                        }

                    },
                },
                MuiBackdrop: {
                    root: {
                        backdropFilter: 'blur(4px)', // Adiciona o efeito de blur
                        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Adiciona uma cor de fundo semitransparente
                    },
                },
                MuiDialog: {
                    paper: {
                        backgroundColor: mode === "light" ? "#FFFFFF" : "#1D203D",
                        color: mode === "light" ? "#1C1C1C" : "#FFFFFF",
                        borderRadius: '16px',
                        maxWidth: '240px',
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                        "-ms-overflow-style": "none",
                        "scrollbar-width": "none",
                    },
                },
                MuiDialogTitle: {
                    root: {
                        backgroundColor: mode === "light" ? "#FFFFFF" : "#1D203D",
                        color: mode === "light" ? "#1C1C1C" : "#FFFFFF",

                    },
                },
                MuiDialogActions: {
                    root: {
                        padding: '16px'

                    },
                },
                MuiDialogContent: {
                    root: {
                        backgroundColor: mode === "light" ? "#FFFFFF" : "#1D203D",
                        color: mode === "light" ? "#1C1C1C" : "#FFFFFF",


                    },
                },

            },

            mode,
        },
        locale
    );

    return (
        <ColorModeContext.Provider value={{ colorMode }}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <SocketContext.Provider value={SocketManager}>
                        <Routes />
                    </SocketContext.Provider>
                </QueryClientProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default App;
