import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { messages } from "./languages";

i18n
  .use(LanguageDetector)  
  .use(initReactI18next)  
  .init({
    debug: false, // Defina como true para ver os logs no console (útil para debugging)
    fallbackLng: 'pt', // Idioma padrão caso o idioma detectado não esteja disponível
    ns: ['translations'], // Nome do namespace usado para as traduções
    defaultNS: 'translations', // Namespace padrão
    resources: messages, // Define as mensagens de tradução
    interpolation: {
      escapeValue: false, // React já faz o escaping de valores, então desativa para evitar problemas
    },
  
  });

export { i18n };