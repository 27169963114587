import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Paper,
    Grid,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import { Formik, Form, Field } from 'formik';
import ButtonWithSpinner from "../ButtonWithSpinner";
import ConfirmationModal from "../ConfirmationModal";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { toast } from "react-toastify";
import usePlans from "../../hooks/usePlans";
import { debounce } from 'lodash';
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    mainPaper: {
        width: '100%',
        flex: 1,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.cardnormal : "#fff",
    },
    tableContainer: {
        width: '100%',
        overflowX: "scroll",
        ...theme.scrollbarStyles,
        borderRadius: '16px',
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.cardnormal : "#fff",
    },
    formField: {
        marginBottom: theme.spacing(1),
    },
    buttonTab: {
        backgroundColor: "#6EB131",
        marginBottom: theme.spacing(1),
        color: "#fff",
        borderRadius: "60px",
    },
    buttonContainer: {
        textAlign: 'right',
        padding: theme.spacing(1)
    }
}));

// Tabela de Planos
export function PlansManagerGrid(props) {
    const { records, onSelect, handleOpenShowConfirmDialog, handleOpenModal } = props;
    const classes = useStyles();

    return (
        <Paper className={classes.tableContainer}>
            <Table
                className={classes.fullWidth}
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="left">Nome</TableCell>
                        <TableCell align="center">Usuários</TableCell>
                        <TableCell align="center">Conexões</TableCell>
                        <TableCell align="center">Filas</TableCell>
                        <TableCell align="center">Valor</TableCell>
                        <TableCell align="center">Campanhas</TableCell>
                        <TableCell align="center">Ações</TableCell> */}
                         <TableCell align="left">{i18n.t("plansManager.table.name")}</TableCell>
                        <TableCell align="center">{i18n.t("plansManager.table.users")}</TableCell>
                        <TableCell align="center">{i18n.t("plansManager.table.connections")}</TableCell>
                        <TableCell align="center">{i18n.t("plansManager.table.queues")}</TableCell>
                        <TableCell align="center">{i18n.t("plansManager.table.value")}</TableCell>
                        <TableCell align="center">{i18n.t("plansManager.table.useCampaigns")}</TableCell>
                        <TableCell align="center">{i18n.t("plansManager.table.actions")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.map((row, key) => (
                        <TableRow key={key}>
                            <TableCell align="left">{row.name || "-"}</TableCell>
                            <TableCell align="center">{row.users || "-"}</TableCell>
                            <TableCell align="center">{row.connections || "-"}</TableCell>
                            <TableCell align="center">{row.queues || "-"}</TableCell>
                            <TableCell align="center">{row.value || "00.00"}</TableCell>
                            <TableCell align="center">{row.useCampaigns ? "Habilitado" : "Desabilitado"}</TableCell>
                            <TableCell align="center">
                                <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
                                    <IconButton
                                        onClick={() => handleOpenShowConfirmDialog(row.id)}
                                        aria-label="delete"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleOpenModal(row)} aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

// Formulário de gerenciamento de planos
export function PlanManagerForm(props) {
    const { onSubmit, initialValue, loading, handleCloseModal } = props;
    const classes = useStyles();

    const [record, setRecord] = useState({
        name: '',
        users: 0,
        connections: 0,
        queues: 0,
        value: 0,
        useCampaigns: true,
        useSchedules: true,
        useInternalChat: true,
        useExternalApi: true,
        useKanban: true,
        useOpenAi: true,
        useIntegrations: true,
    });

    useEffect(() => {
        setRecord(initialValue);
    }, [initialValue]);

    return (
        <Formik
            enableReinitialize
            initialValues={record}
            onSubmit={(values, { resetForm }) => {
                onSubmit(values);
                resetForm();
            }}
        >
            {() => (
                <Form className={classes.fullWidth}>
                    <Grid container spacing={4}>
                        {/* Primeira linha - Nome */}
                        <Grid xs={12} item >
                            <Field
                                as={TextField}
                                // label="Nome"
                                label={i18n.t("plansManager.form.name")}
                                name="name"
                                variant="outlined"
                                fullWidth
                                className={classes.formField}
                                style={{ marginTop: '16px' }}
                            />
                        </Grid>

                        {/* Segunda linha - Usuários e Conexões */}
                        <Grid xs={12} sm={6} item>
                            <Field
                                as={TextField}
                                // label="Usuários"
                                label={i18n.t("plansManager.form.users")}
                                name="users"
                                type="number"
                                variant="outlined"
                                fullWidth
                                className={classes.formField}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <Field
                                as={TextField}
                                // label="Conexões"
                                label={i18n.t("plansManager.form.connections")}
                                name="connections"
                                type="number"
                                variant="outlined"
                                fullWidth
                                className={classes.formField}
                            />
                        </Grid>

                        {/* Terceira linha - Setores e Valor */}
                        <Grid xs={12} sm={6} item>
                            <Field
                                as={TextField}
                                // label="Setores"
                                label={i18n.t("plansManager.form.queues")}
                                name="queues"
                                type="number"
                                variant="outlined"
                                fullWidth
                                className={classes.formField}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <Field
                                as={TextField}
                                // label="Valor"
                                label={i18n.t("plansManager.form.value")}
                                name="value"
                                type="number"
                                variant="outlined"
                                fullWidth
                                className={classes.formField}
                            />
                        </Grid>

                        {/* Quarta linha - Campanhas e Agendamentos */}
                        <Grid xs={12} sm={6} item>
                            <FormControl variant="outlined" fullWidth className={classes.formField}>
                                {/* <InputLabel>Campanhas</InputLabel> */}
                                <InputLabel>{i18n.t("plansManager.form.useCampaigns")}</InputLabel>
                                <Field as={Select} name="useCampaigns" label="Campanhas">
                                    {/* <MenuItem value={true}>Habilitado</MenuItem>
                                    <MenuItem value={false}>Desabilitado</MenuItem> */}
                                     <MenuItem value={true}>{i18n.t("plansManager.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plansManager.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <FormControl variant="outlined" fullWidth className={classes.formField}>
                                {/* <InputLabel>Agendamentos</InputLabel> */}
                                <InputLabel>{i18n.t("plansManager.form.useSchedules")}</InputLabel>
                                <Field as={Select} name="useSchedules" label="Agendamentos">
                                    {/* <MenuItem value={true}>Habilitado</MenuItem>
                                    <MenuItem value={false}>Desabilitado</MenuItem> */}
                                    <MenuItem value={true}>{i18n.t("plansManager.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plansManager.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* Quinta linha - Chat interno e API externa */}
                        <Grid xs={12} sm={6} item>
                            <FormControl variant="outlined" fullWidth className={classes.formField}>
                                {/* <InputLabel>Chat Interno</InputLabel> */}
                                <InputLabel>{i18n.t("plansManager.form.useInternalChat")}</InputLabel>
                                <Field as={Select} name="useInternalChat" label="Chat Interno">
                                    {/* <MenuItem value={true}>Habilitado</MenuItem>
                                    <MenuItem value={false}>Desabilitado</MenuItem> */}
                                     <MenuItem value={true}>{i18n.t("plansManager.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plansManager.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <FormControl variant="outlined" fullWidth className={classes.formField}>
                                {/* <InputLabel>API Externa</InputLabel> */}
                                <InputLabel>{i18n.t("plansManager.form.useExternalApi")}</InputLabel>
                                <Field as={Select} name="useExternalApi" label="API Externa">
                                    {/* <MenuItem value={true}>Habilitado</MenuItem>
                                    <MenuItem value={false}>Desabilitado</MenuItem> */}
                                    <MenuItem value={true}>{i18n.t("plansManager.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plansManager.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* Sexta linha - Kanban e Open AI */}
                        <Grid xs={12} sm={6} item>
                            <FormControl variant="outlined" fullWidth className={classes.formField}>
                                {/* <InputLabel>Kanban</InputLabel> */}
                                <InputLabel>{i18n.t("plansManager.form.useKanban")}</InputLabel>
                                <Field as={Select} name="useKanban" label="Kanban">
                                    {/* <MenuItem value={true}>Habilitado</MenuItem>
                                    <MenuItem value={false}>Desabilitado</MenuItem> */}
                                      <MenuItem value={true}>{i18n.t("plansManager.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plansManager.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <FormControl variant="outlined" fullWidth className={classes.formField}>
                                {/* <InputLabel>Open AI</InputLabel> */}
                                <InputLabel>{i18n.t("plansManager.form.useOpenAi")}</InputLabel>
                                <Field as={Select} name="useOpenAi" label="Open AI">
                                    {/* <MenuItem value={true}>Habilitado</MenuItem>
                                    <MenuItem value={false}>Desabilitado</MenuItem> */}
                                      <MenuItem value={true}>{i18n.t("plansManager.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plansManager.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* Sétima linha - Integrações */}
                        <Grid xs={12} item>
                            <FormControl variant="outlined" fullWidth className={classes.formField}>
                                {/* <InputLabel>Integrações</InputLabel> */}
                                <InputLabel>{i18n.t("plansManager.form.useIntegrations")}</InputLabel>
                                <Field as={Select} name="useIntegrations" label="Integrações">
                                    {/* <MenuItem value={true}>Habilitado</MenuItem>
                                    <MenuItem value={false}>Desabilitado</MenuItem> */}
                                     <MenuItem value={true}>{i18n.t("plansManager.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plansManager.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <DialogActions style={{ justifyContent: "flex-end" }}>

                        <div style={{ display: 'flex', gap: '8px' }}>
                            <Button
                                onClick={handleCloseModal}
                                style={{ size: "medium", padding: "8px 16px", backgroundColor: '#111433', color: '#fff' }}
                            >
                                {/* Cancelar */}
                                {i18n.t("plansManager.form.cancel")}
                            </Button>
                            <ButtonWithSpinner
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={loading}
                            >
                                {/* Salvar */}
                                {i18n.t("plansManager.form.save")}
                            </ButtonWithSpinner>
                        </div>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}


// Componente principal
export default function PlansManager() {
    const classes = useStyles();
    const { list, save, update, remove, listSearch } = usePlans();

    const [records, setRecords] = useState([]);
    const [record, setRecord] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        loadPlans();
    }, []);

    const loadPlans = async () => {
        setLoading(true);
        try {
            const planList = await list();
            setRecords(planList);
        } catch (e) {
            // toast.error("Não foi possível carregar a lista de registros");
            toast.error(i18n.t("plansManager.toast.errorLoadPlans"));
        }
        setLoading(false);
    };

    const fetchPlans = async (searchParam = "") => {
        setLoading(true);
        try {
            const { plans } = await listSearch(searchParam);
            setRecords(plans);
        } catch (e) {
            // toast.error("Erro ao buscar planos");
            toast.error(i18n.t("plansManager.toast.errorSearchPlans"));
        }
        setLoading(false);
    };

    // Atualiza os registros ao digitar no campo de busca
    useEffect(() => {
        const debouncedFetch = debounce(() => {
            fetchPlans(searchTerm);
        }, 500);

        debouncedFetch();
        return () => {
            debouncedFetch.cancel();
        };
    }, [searchTerm]);

    const handleOpenModal = (plan = null) => {
        setRecord(plan || {
            name: '',
            users: 0,
            connections: 0,
            queues: 0,
            value: 0,
            useCampaigns: true,
        });
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenShowConfirmDialog = (id) => {
        setSelectedId(id);
        setShowConfirmDialog(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await remove(selectedId);
            await loadPlans();
            // toast.success("Plano excluído com sucesso!");
            toast.success(i18n.t("plansManager.toast.successDelete"));
            handleCloseModal();
        } catch (e) {
            // toast.error("Erro ao excluir o plano.");
            toast.error(i18n.t("plansManager.toast.errorDelete"));
        }
        setLoading(false);
        setShowConfirmDialog(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSubmit = async (data) => {
        setLoading(true);
        try {
            if (data.id) {
                // Se o plano já existe (possui ID), ele será atualizado
                await update(data);
                // toast.success("Plano atualizado com sucesso!");
                toast.success(i18n.t("plansManager.toast.successUpdate"));
            } else {
                // Se não existe ID, será um novo plano a ser adicionado
                await save(data);
                // toast.success("Plano adicionado com sucesso!");
                toast.success(i18n.t("plansManager.toast.successAdd"));
            }
            await loadPlans();  // Recarrega a lista de planos
            handleCloseModal(); // Fecha o modal
        } catch (e) {
            // toast.error("Erro ao salvar o plano. Verifique os campos e tente novamente.");
            toast.error(i18n.t("plansManager.toast.errorSave"));
        }
        setLoading(false);
    };



    return (
        <Paper className={classes.mainPaper} elevation={0}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <div className={classes.buttonContainer} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: "8px" }} >
                        <TextField
                            variant="outlined"
                            // laceholder="Buscar"
                            placeholder={i18n.t("plansManager.searchPlaceholder")}
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className={classes.formField}
                            size="small"
                            style={{ borderRadius: '16px' }}
                        />
                        <Button
                            size="medium"
                            className={classes.buttonTab}
                            onClick={() => handleOpenModal()}
                            style={{ paddingLeft: '24px', paddingRight: '24px' }}
                        >
                            {/* + Adicionar Plano */}
                            {i18n.t("plansManager.addButton")}
                        </Button>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <PlansManagerGrid
                        records={records}
                        handleOpenShowConfirmDialog={handleOpenShowConfirmDialog}
                        handleOpenModal={handleOpenModal}
                      
                    />
                </Grid>
            </Grid>

            {/* Modal para adicionar ou editar plano */}
            <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
                {/* <DialogTitle>{record.id ? "Editar Plano" : "Adicionar Plano"}</DialogTitle> */}
                <DialogTitle>{record.id ? i18n.t("plansManager.form.editTitle") : i18n.t("plansManager.form.addTitle")}</DialogTitle>
                <DialogContent>
                    <PlanManagerForm
                        initialValue={record}
                        onSubmit={handleSubmit}
                        loading={loading}
                        handleCloseModal={handleCloseModal}
                    />
                </DialogContent>
            </Dialog>

            {/* Modal de confirmação para excluir plano */}
            <ConfirmationModal
                title="Exclusão de Plano"
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={handleDelete}
            >
                {/* Deseja realmente excluir esse plano? */}
                {i18n.t("plansManager.confirmDeletion.message")}
            </ConfirmationModal>
        </Paper>
    );
}
