import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, Stack, TextField } from '@mui/material';
import Typography from "@material-ui/core/Typography";
import api from '../../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { makeStyles } from "@material-ui/core/styles";
import './button.css';
import ButtonWithText from "../../components/ButtonWithText";
import { useTranslation } from 'react-i18next';  // Aqui está o import correto

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.padding,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
    },
    filterButton: {
        backgroundColor: "#6EB131",
        margin: "0 auto",
        color: "#fff",
        borderRadius: "16px",
    },
    textField: {
        "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
            color: `${theme.palette.type === 'dark' ? theme.palette.textPrimary : "grey"} !important`,
        },
        "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.type === 'dark' ? theme.palette.textPrimary : "grey"} !important`,
        },
        "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root": {
            color: `${theme.palette.type === 'dark' ? theme.palette.textPrimary : "grey"} !important`,
        },
        "& .MuiSvgIcon-root": {
            color: `${theme.palette.type === 'dark' ? theme.palette.textPrimary : "grey"} !important`, // Cor do ícone de data
        },
    },
}));

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export const ChatsUser = () => {
    const classes = useStyles();
    const { t } = useTranslation(); // Usar `useTranslation` dentro do componente

    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState({ data: [] });

    const companyId = localStorage.getItem("companyId");

    useEffect(() => {
        handleGetTicketsInformation();
    }, []);

    const dataCharts = {

        labels: ticketsData && ticketsData?.data.length > 0 && ticketsData?.data.map((item) => item.nome),
        datasets: [
            {
                data: ticketsData?.data.length > 0 && ticketsData?.data.map((item, index) => {
                    return item.quantidade
                }),
                backgroundColor: '#2DDD7F',
            },

        ],
    };

    const handleGetTicketsInformation = async () => {
        try {

            const { data } = await api.get(`/dashboard/ticketsUsers?initialDate=${format(initialDate, 'yyyy-MM-dd')}&finalDate=${format(finalDate, 'yyyy-MM-dd')}&companyId=${companyId}`);
            setTicketsData(data);
        } catch (error) {
            toast.error('Erro ao obter informações da conversa');
        }
    }

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: true,
                text: t("dashboard.bar_chart.y_axis_title"), // Use `t` aqui
                position: 'left',
            },
            datalabels: {
                display: true,
                anchor: 'start',
                offset: -30,
                align: "start",
                color: "#fff",
                textStrokeColor: "#000",
                textStrokeWidth: 2,
                font: {
                    size: 20,
                    weight: "bold"
                },
            }
        },
    };

    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {t("dashboard.bar_chart.title")}
            </Typography>

            <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ my: 2, }} >

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={initialDate}
                        onChange={(newValue) => { setInitialDate(newValue) }}
                        label={t("dashboard.bar_chart.data.start")}
                        className={classes.textField}
                        renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}

                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={finalDate}
                        onChange={(newValue) => { setFinalDate(newValue) }}
                        label={t("dashboard.bar_chart.data.end")}
                        className={classes.textField}
                        renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
                    />
                </LocalizationProvider>

                <ButtonWithText onClick={handleGetTicketsInformation} variant='contained'>{t("dashboard.bar_chart.buttons.filter")}</ButtonWithText>

            </Stack>
            <Bar options={chartOptions} data={dataCharts} style={{ maxWidth: '100%', maxHeight: '280px', }} />
        </>
    );
}
