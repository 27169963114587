import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import { I18nextProvider } from "react-i18next";
import { i18n } from "./translate/i18n";

import App from "./App";

ReactDOM.render(
	<CssBaseline>
		<I18nextProvider i18n={i18n}>
			<App />
		</I18nextProvider>
	</CssBaseline>,
	document.getElementById("root")
);

// ReactDOM.render(
// 	<React.StrictMode>
// 		<CssBaseline>
// 			<App />
// 		</CssBaseline>,
//   </React.StrictMode>

// 	document.getElementById("root")
// );
