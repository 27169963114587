import React, { useState } from "react";
import { i18n } from "../../translate/i18n";
import { Button } from "@material-ui/core";

const FileInputWithI18n = ({ setFile }) => {
  const [fileName, setFileName] = useState(i18n.t("messagesAPI.mediaMessage.noFileChosen"));

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files);
      setFileName(e.target.files[0].name); // Atualiza com o nome do arquivo escolhido
    } else {
      setFileName(i18n.t("messagesAPI.mediaMessage.noFileChosen")); // Reseta se nenhum arquivo for escolhido
    }
  };

  return (
    <div>
      {/* O input real é escondido */}
      <input
        id="medias"
        type="file"
        name="medias"
        onChange={handleFileChange}
        required
        style={{ display: "none" }} // Esconde o input
      />

      {/* Label customizado para o input de arquivo */}
      <label htmlFor="medias">
        <Button
          variant="contained"
          color="primary"
          component="span"
        >
          {i18n.t("messagesAPI.mediaMessage.chooseFile")} {/* Texto customizado para escolher arquivo */}
        </Button>
      </label>

      {/* Exibe o nome do arquivo ou o texto padrão se nenhum arquivo for escolhido */}
      <span style={{ marginLeft: "10px" }}>{fileName}</span>
    </div>
  );
};

export default FileInputWithI18n;
