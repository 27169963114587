import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
  Badge,
  Collapse,
  List,
  makeStyles,
} from "@material-ui/core";
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import {
  SettingsOutlined as SettingsOutlinedIcon,
  FlashOn as FlashOnIcon,
  HelpOutline as HelpOutlineIcon,
  CodeRounded as CodeRoundedIcon,
  Event as EventIcon,
  EventAvailable as EventAvailableIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  People as PeopleIcon,
  ListAlt as ListIcon,
  TableChart as TableChartIcon,
} from "@material-ui/icons";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { SocketContext } from "../context/Socket/SocketContext";
import api from "../services/api";
import toastError from "../errors/toastError";
import usePlans from "../hooks/usePlans";
import useVersion from "../hooks/useVersion";
import { Can } from "../components/Can";
import { isArray } from "lodash";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PsychologyIcon from '@mui/icons-material/Psychology';
import MediationIcon from '@mui/icons-material/Mediation';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import AttachmentIcon from '@mui/icons-material/Attachment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import GroupsIcon from '@mui/icons-material/Groups';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const useStyles = makeStyles((theme) => ({
  listItem: {
    overflowX: 'visible', // Certifica-se de que o overflow permita a visualização completa
  position: 'relative',
    borderRadius: '100px',
    ontWeight: '600',
    paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
    '& .MuiTypography-root': {
      fontSize: '0.875rem',
    },
  },
  selected: {
    backgroundColor: theme.palette.sidebarbuttons,
    fontWeight: '600',
    color: theme.palette.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.contrastText,
    },
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
    // paddingRight: theme.spacing(4)
  },
  listItemText: {
    fontSize: '0.875rem',
    fontWeight: '600',
  },
  ListSubheader: {
    height: 26,
    marginTop: "-15px",
    marginBottom: "-10px",
  },
  sidebar: {
    overflow: 'visible', // Remove overflow horizontal
  },
}));

// Custom Link Component for List Items
function ListItemLink(props) {
  const { icon, primary, to, className, selected } = props;
  const classes = useStyles(); // Certifique-se de que useStyles está sendo chamado dentro do componente
  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => (
      <RouterLink to={to} ref={ref} {...itemProps} />
    )),
    [to]
  );

  return (
    <li>
      <ListItem button dense component={renderLink} className={selected ? `${className} ${classes.selected}` : className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

// Reducer for handling chat state
const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_CHATS":
      const chats = action.payload;
      const newChats = [];

      if (isArray(chats)) {
        chats.forEach((chat) => {
          const chatIndex = state.findIndex((u) => u.id === chat.id);
          if (chatIndex !== -1) {
            state[chatIndex] = chat;
          } else {
            newChats.push(chat);
          }
        });
      }

      return [...state, ...newChats];

    case "UPDATE_CHATS":
      const chat = action.payload;
      const chatIndex = state.findIndex((u) => u.id === chat.id);

      if (chatIndex !== -1) {
        state[chatIndex] = chat;
        return [...state];
      } else {
        return [chat, ...state];
      }

    case "DELETE_CHAT":
      return state.filter((chat) => chat.id !== action.payload);

    case "RESET":
      return [];

    case "CHANGE_CHAT":
      return state.map((chat) =>
        chat.id === action.payload.chat.id ? action.payload.chat : chat
      );

    default:
      return state;
  }
};

const MainListItems = ({ drawerClose, collapsed }) => {
  const classes = useStyles();
  const location = useLocation(); // Current location
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useContext(AuthContext);
  const socketManager = useContext(SocketContext);
  const history = useHistory();
  const { getPlanCompany } = usePlans();
  const { getVersion } = useVersion();

  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showKanban, setShowKanban] = useState(false);
  const [showOpenAi, setShowOpenAi] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const [showSchedules, setShowSchedules] = useState(false);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);
  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const [version, setVersion] = useState("");

  // Fetch and set version
  useEffect(() => {
    async function fetchVersion() {
      const _version = await getVersion();
      setVersion(_version.version);
    }
    fetchVersion();
  }, [getVersion]);

  // Reset chats on search parameter change
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  // Fetch plan configurations
  useEffect(() => {
    async function fetchData() {
      const companyId = user.companyId;
      const planConfigs = await getPlanCompany(undefined, companyId);

      setShowCampaigns(planConfigs.plan.useCampaigns);
      setShowKanban(planConfigs.plan.useKanban);
      setShowOpenAi(planConfigs.plan.useOpenAi);
      setShowIntegrations(planConfigs.plan.useIntegrations);
      setShowSchedules(planConfigs.plan.useSchedules);
      setShowInternalChat(planConfigs.plan.useInternalChat);
      setShowExternalApi(planConfigs.plan.useExternalApi);
    }
    fetchData();
  }, [getPlanCompany, user.companyId]);

  // Fetch chats based on search parameters and page number
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  // Handle socket events for chats
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message" || data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [socketManager]);

  // Set connection warning based on WhatsApp status
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => [
          "qrcode",
          "PAIRING",
          "DISCONNECTED",
          "TIMEOUT",
          "OPENING",
        ].includes(whats.status));
        setConnectionWarning(offlineWhats.length > 0);
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  // Calculate unread chats count
  useEffect(() => {
    const unreadsCount = chats.reduce((count, chat) => {
      return count + chat.users.reduce((userCount, chatUser) => {
        return chatUser.userId === user.id ? userCount + chatUser.unreads : userCount;
      }, 0);
    }, 0);

    setInvisible(unreadsCount === 0);
  }, [chats, user.id]);

  // Fetch chats from API
  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  // Handle logout
  const handleClickLogout = () => {
    handleLogout();
  };

  return (
    <div onClick={drawerClose} className={classes.sidebar}>
      <Can
        role={user.profile}
        perform="dashboard:view"
        yes={() => (
          <ListItemLink
            to="/"
            primary="Dashboard"
            icon={<DashboardIcon />}
            className={classes.listItem}
            selected={location.pathname === '/'}
          />
        )}
      />

      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<SupportAgentIcon />}
        className={classes.listItem}
        selected={location.pathname === '/tickets'}
      />

      {showKanban && (
        <ListItemLink
          to="/kanban"
          primary={i18n.t("Kanban")}
          icon={<TableChartIcon />}
          className={classes.listItem}
          selected={location.pathname === '/kanban'}
        />
      )}

      <ListItemLink
        to="/quick-messages"
        primary={i18n.t("mainDrawer.listItems.quickMessages")}
        icon={<FlashOnIcon />}
        className={classes.listItem}
        selected={location.pathname === '/quick-messages'}
      />

      <ListItemLink
        to="/todolist"
        primary={i18n.t("Tarefas")}
        icon={<ContentPasteIcon />}
        className={classes.listItem}
        selected={location.pathname === '/todolist'}
      />

      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactsOutlinedIcon />}
        className={classes.listItem}
        selected={location.pathname === '/contacts'}
      />

      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<EventIcon />}
        className={classes.listItem}
        selected={location.pathname === '/schedules'}
      />

      <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon={<LocalOfferOutlinedIcon />}
        className={classes.listItem}
        selected={location.pathname === '/tags'}
      />
      {/* CHAT INTERNO */}
      {/* <ListItemLink
        to="/chats"
        primary={i18n.t("mainDrawer.listItems.chats")}
        icon={
          <Badge color="secondary" variant="dot" invisible={invisible}>
            <ForumIcon />
          </Badge>
        }
        className={classes.listItem}
        selected={location.pathname === '/chats'}
      /> */}

      {/* <ListItemLink
        to="/helps"
        primary={i18n.t("mainDrawer.listItems.helps")}
        icon={<HelpOutlineIcon />}
        className={classes.listItem}
        selected={location.pathname === '/helps'}
      /> */}

      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            <ListSubheader
              hidden={collapsed}
              className={classes.ListSubheader}
              inset
              color="inherit"
              style={{ position: "relative", fontSize: "12px", textAlign: "left", paddingLeft: 20, marginBottom: "10px",  marginTop: "5px", height: "25px" }}
            >
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>

            {showCampaigns && (
              <>
                <ListItem
                  button
                  onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <EventAvailableIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.t("mainDrawer.listItems.campaigns")}
                  />
                  {openCampaignSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
                <Collapse
                  style={{ paddingLeft: 15 }}
                  in={openCampaignSubmenu}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      onClick={() => history.push("/campaigns")}
                      button
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <ListIcon />
                      </ListItemIcon> 
                      <ListItemText primary={i18n.t("mainDrawer.menu.listing")} />
                    </ListItem>
                    <ListItem
                      onClick={() => history.push("/contact-lists")}
                      button
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary={i18n.t("mainDrawer.menu.contacts_list")} />
                    </ListItem>
                    <ListItem
                      onClick={() => history.push("/campaigns-config")}
                      button
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <SettingsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={i18n.t("mainDrawer.menu.settings")} />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}

            {user.super && (
              <ListItemLink
                to="/announcements"
                primary={i18n.t("mainDrawer.listItems.annoucements")}
                icon={<ErrorOutlineIcon />}
                className={classes.listItem}
                selected={location.pathname === '/announcements'}
              />
            )}

            {showOpenAi && (
              <ListItemLink
                to="/prompts"
                primary={i18n.t("mainDrawer.listItems.prompts")}
                icon={<PsychologyIcon />}
                className={classes.listItem}
                selected={location.pathname === '/prompts'}
              />
            )}

            {showIntegrations && (
              <ListItemLink
                to="/queue-integration"
                primary={i18n.t("mainDrawer.listItems.queueIntegration")}
                icon={<MediationIcon />}
                className={classes.listItem}
                selected={location.pathname === '/queue-integration'}
              />
            )}

            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge badgeContent={connectionWarning ? "!" : 0} style={{zIndex:999999}} color="error">
                  <WifiTetheringIcon />
                </Badge>
              }
              className={classes.listItem}
              selected={location.pathname === '/connections'}
            />

            <ListItemLink
              to="/files"
              primary={i18n.t("mainDrawer.listItems.files")}
              icon={<AttachmentIcon />}
              className={classes.listItem}
              selected={location.pathname === '/files'}
            />

            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<MoreHorizIcon />}
              className={classes.listItem}
              selected={location.pathname === '/queues'}
            />

            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<GroupsIcon />}
              className={classes.listItem}
              selected={location.pathname === '/users'}
            />

            {showExternalApi && (
              <ListItemLink
                to="/messages-api"
                primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                icon={<CodeRoundedIcon />}
                className={classes.listItem}
                selected={location.pathname === '/messages-api'}
              />
            )}

            <ListItemLink
              to="/financeiro"
              primary={i18n.t("mainDrawer.listItems.financeiro")}
              icon={<AttachMoneyIcon />}
              className={classes.listItem}
              selected={location.pathname === '/financeiro'}
            />

            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlinedIcon />}
              className={classes.listItem}
              selected={location.pathname === '/settings'}
            />
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;
