import { Chip, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { Close } from '@material-ui/icons';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.cardnormal : "#fff",
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: "4px 14px",
    },
    customInput: {
        "& .MuiInputBase-input": {
          minWidth: "125px",
          border: "none",
          backgroundColor:
            theme.palette.type === "dark"
              ? theme.palette.cardnormal
              : "#fff",
          color: `${
            theme.palette.type === "dark" ? "#fff" : theme.palette.text.primary
          } !important`,
        },
        "& .MuiInputBase-input::placeholder": {
          color: theme.palette.type === "dark" ? "#fff" : "#666", // Ajuste a cor do placeholder
          opacity: 1, // Isso é importante para garantir que o estilo seja aplicado
        },
      },
    customAutocomplete: {
        "& .MuiInputBase-root": {
        display: "flex",
        flexDirection: "row-reverse", // Reverte a ordem para as tags aparecerem à direita
        alignItems: "center",
        flexWrap: "wrap", // Garante que as tags e o input estejam na mesma linha
      },},
      tagIcon: {
        width: "6px", // Tamanho da bolinha
        height: "6px",
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.cardnormal : "#fff", // Cor da bolinha
        borderRadius: "100%", // Torna a bolinha circular
        display: "inline-block",
      }
  }));

export function TagsContainer({ ticket }) {
    const classes = useStyles(); // Aqui você chama o hook

    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState([]);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (isMounted.current) {
            loadTags().then(() => {
                if (Array.isArray(ticket.tags)) {
                    setSelecteds(ticket.tags);
                } else {
                    setSelecteds([]);
                }
            });
        }
    }, [ticket]);

    const createTag = async (data) => {
        console.log(data.name + "data length", data.name.length)
        if (data.name.length < 3) {
            toastError("Tag must be at least 3 characters long");
            return;
        }
        try {
            const { data: responseData } = await api.post(`/tags`, data);
            return responseData;
        } catch (err) {
            toastError(err);
            return;
        }
    }

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags/list`);
            if (!Array.isArray(data)) {
                toastError("Erro: A resposta da API não é uma lista.");
                return;
            }
            setTags(data);
        } catch (err) {
            toastError(err);
        }
    }

    const syncTags = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags/sync`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }

    const onChange = async (value, reason) => {
        let optionsChanged = []
        if (reason === 'create-option') {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item });
                        if (newTag && newTag.name) { // Verifique se a nova tag é válida
                            optionsChanged.push(newTag);
                        } else {
                            toastError("Falha ao criar uma nova tag.");
                        }
                    } else {
                        optionsChanged.push(item);
                    }
                }
            }
            await loadTags();
        } else {
            optionsChanged = value;
        }
        setSelecteds(optionsChanged);
        await syncTags({ ticketId: ticket.id, tags: optionsChanged });
    }

    return (
        <Paper className={classes.container}>
            <Autocomplete
                multiple
                size="small"
                options={tags}
                value={selecteds}
                freeSolo
                onChange={(e, v, r) => onChange(v, r)}
                getOptionLabel={(option) => option.name}
                classes={{ root: classes.customAutocomplete }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        if (!option || !option.name) {
                            console.error("Tag inválida ou indefinida:", option);
                            return null; // ou exibir um fallback padrão
                        }
                
                        return (
                            <Chip
                                variant="outlined"
                                style={{
                                    background: "#067647", // Verifica a cor ou aplica uma padrão
                                    color: "#FFF",
                                    marginLeft: 4,
                                    fontWeight: 600,
                                    borderRadius: "26px 8px 8px 26px",
                                    fontSize: "0.8em",
                                    whiteSpace: "nowrap",
                                    border: 'none',
                                }}
                                label={option.name.toUpperCase()}
                                deleteIcon={<Close style={{ color: "#FFF", fontWeight: "bold", marginLeft: 8 }} />}
                                {...getTagProps({ index })}
                                size="small"
                                icon={(
                                    <div style={{
                                        backgroundColor: "#1E8359",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "26px 0 0 26px",
                                        margin: "0"
                                    }}>
                                        <span className={classes.tagIcon} />
                                    </div>
                                )}
                            />
                        );
                    })
                }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Tags"
                    className={classes.customInput}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <LocalOfferIcon style={{ marginRight: 2, fontSize:"18px" }} /> // Ícone adicionado antes do input
                        ),
                    }}
                    />
                )}
                PaperComponent={({ children }) => (
                    <Paper style={{ width: 400, marginLeft: 12 }}>
                        {children}
                    </Paper>
                )}
            />
        </Paper>
    )
}